import { ConfigProvider, Layout as AntLayout, notification } from 'antd'
import axios from 'axios'
import React, { FC, useCallback, useMemo } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import PianoAnalyticsContext from './context/PianoAnalyticsContext'
import './App.less'
import Footer from './components/Layout/Footer'
import Header from './components/Layout/Header/Header'
import Layout from './components/Layout/Layout'
import ProtectedRoute from './components/ProtectedRoute'
import ApiContext, { ApiContextI } from './context/ApiContext'
import AuthenticationContext, {
  AuthenticationContextI,
} from './context/AuthenticationContext'
import UserContext, { UserContextI } from './context/UserContext'
import MyProfilDto from './domain/MyProfilDto'
import { AccessForbidden } from './pages/403'
import CG from './pages/Cgu/Cgu'
import CguDetails from './pages/Cgu/CguDetails'
import Clients from './pages/Client/Clients'
import InitClient from './pages/Client/InitClient'
import ModifyMyClientProfil from './pages/Client/ModifyMyClientProfil'
import DemandesContact from './pages/Events/DemandesContact'
import DemandesContactClient from './pages/Events/DemandesContactClient'
import InitAccount from './pages/Login/InitAccount'
import Login from './pages/Login/Login'
import ResetPassword from './pages/Login/ResetPassword'
import Redirection from './pages/Redirection/Redirection'
import Startups from './pages/Startup/All/Startups'
import InitStartup from './pages/Startup/Init/InitStartup'
import MyStartup from './pages/Startup/Show/MyStartup'
import ModifyMyProfile from './pages/Users/ModifyMyProfile'
import Users from './pages/Users/Users'
import Api from './services/Api'
import Authentication, { AuthReq } from './services/Authentication'
import usePianoAnalytics from './services/usePianoAnalytics.hook'
import useSessionStorage from './services/useStorage.hook'
import { Roles } from './utils/roles.enum'
import frFR from 'antd/lib/locale/fr_FR'
import enGB from 'antd/lib/locale/en_GB'
import Ecosystems from './pages/Ecosystems/Ecosystems'
import { Dashboard } from './pages/Dashboard/Dashboard'
import ModifyStartup from './pages/Startup/Update/ModifyStartup'
import { LegalNotices } from './pages/Cgu/LegalNotices'

type FixMeLaterMap = { [x: string]: any }
export type FixMeLater = string | any | any[] | FixMeLaterMap | FixMeLaterMap[]

const Page: FC = ({ children }): JSX.Element => {
  return (
    <Layout>
      <Header />
      <AntLayout.Content>{children}</AntLayout.Content>
      <Footer />
    </Layout>
  )
}

function App() {
  const [user, setUser] = useSessionStorage('user', null)
  const history = useHistory()
  const pianoAnalytics = usePianoAnalytics(user)
  const initialLocale = navigator.language.includes('fr') ? frFR : enGB

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        //Si unauthorized redirection sur login
        //tu perds pas ton url
        setUser(null)
      }
      return Promise.reject(error)
    },
  )

  const userCtxImpl: UserContextI = useMemo(
    () => ({
      user: user,
      setUser: setUser,
      logout: () =>
        Authentication.logout()
          .catch(() => {
            // This is intentional, so logout error in backend don't block setting user at null
          })
          .then(() => {
            setUser(null)
            notification['success']({
              message: 'Succès',
              description: 'Vous êtes déconnecté',
            })
          }),
    }),
    [user, setUser],
  )
  const apiCtxImpl: ApiContextI = Api
  const authWorkflow = useCallback(
    (auth: AuthReq, redirect?: string) =>
      Authentication.authenticate(auth)
        .then(() => {
          pianoAnalytics.login()
          // redirect before setting new user (initPassword and resetPassword workflow...)
          if (redirect) {
            history.push(redirect)
          }
        })
        .then(Authentication.getMe)
        .then((profileDto: MyProfilDto) =>
          setUser({ username: auth.username, ...profileDto }),
        ),
    [setUser, history, pianoAnalytics],
  )
  const authenticationCtxImpl: AuthenticationContextI = useMemo(
    () => ({
      authenticate: (auth) => authWorkflow(auth),
      authenticateAndRedirect: (auth) => authWorkflow(auth, '/'),
      getMe: Authentication.getMe,
      validateToken: Authentication.validateToken,
      initAccount: Authentication.initAccount,
      resetPassword: Authentication.resetPassword,
      sendEmailResetPassword: Authentication.sendEmailResetPassword,
    }),
    [authWorkflow],
  )

  return (
    <ConfigProvider locale={initialLocale}>
      <UserContext.Provider value={userCtxImpl}>
        <PianoAnalyticsContext.Provider value={pianoAnalytics}>
          {user ? (
            <ApiContext.Provider value={apiCtxImpl}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Page>
                  <Switch>
                    <ProtectedRoute
                      path={`/dashboard`}
                      component={Dashboard}
                      roles={Roles.INNO}
                    />
                    <ProtectedRoute
                      path={`/startups/init`}
                      component={InitStartup}
                      roles={Roles.STARTUP}
                    />
                    <ProtectedRoute
                      path={`/startups/my/edit`}
                      component={ModifyStartup}
                      roles={Roles.STARTUP}
                    />
                    <ProtectedRoute
                      path={`/startups/my`}
                      component={MyStartup}
                      roles={Roles.STARTUP}
                    />
                    <ProtectedRoute
                      path={`/startups`}
                      component={Startups}
                      roles={[Roles.INNO, Roles.CLIENT, Roles.BANQUIER]}
                    />
                    <ProtectedRoute
                      path={`/linkings`}
                      component={DemandesContactClient}
                      roles={Roles.CLIENT} 
                    />
                    <ProtectedRoute
                      path={`/events`}
                      component={DemandesContact}
                      roles={Roles.INNO}
                    />
                    <ProtectedRoute
                      path={`/client/init`}
                      component={InitClient}
                      roles={Roles.CLIENT}
                    />
                    <ProtectedRoute
                      path={`/client/modify`}
                      component={ModifyMyClientProfil}
                      roles={Roles.CLIENT}
                    />
                    <ProtectedRoute
                      path={`/clients`}
                      component={Clients}
                      roles={Roles.INNO}
                    />
                    <ProtectedRoute
                      path={`/ecosystem`}
                      component={Ecosystems}
                      roles={Roles.INNO}
                    />
                    <Route path={`/profile`} component={ModifyMyProfile} />
                    <ProtectedRoute
                      path={`/users`}
                      component={Users}
                      roles={Roles.INNO}
                    />
                    <Route path="/CG" component={CG} />
                    <Route
                      path="/initPassword"
                      render={() => {
                        // ensure redirection to the second block even if old cookie remain
                        setUser(null)
                        return null
                      }}
                    />
                    <Route
                      path="/resetPassword"
                      render={() => {
                        // ensure redirection to the second block even if old cookie remain
                        setUser(null)
                        return null
                      }}
                    />
                    <Route path="/cgudetails" component={CguDetails} />
                    <Route path="/403" component={AccessForbidden} />
                    <Route path="/" component={Redirection} />
                  </Switch>
                </Page>
              </QueryParamProvider>
            </ApiContext.Provider>
          ) : (
            <AuthenticationContext.Provider value={authenticationCtxImpl}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  <Route path="/initPassword" component={InitAccount} />
                  <Route path="/resetPassword" component={ResetPassword} />
                  <Route path="/legalnotices" component={LegalNotices} />
                  <Route path="/" component={Login} />
                </Switch>
              </QueryParamProvider>
            </AuthenticationContext.Provider>
          )}
        </PianoAnalyticsContext.Provider>
      </UserContext.Provider>
    </ConfigProvider>
  )
}

export default App
