import common from './common.json'
import startup from './startup.json'
import client from './client.json'
import event from './event.json'
import archived from './archived.json'
import ecosystem from './ecosystem.json'
import input from './input.json'
import login from './login.json'
import domain from './domain.json'
import dashboard from './dashboard.json'
import news from './news.json'
import general_terms_of_use from './general_terms_of_use.json'
import users from './users.json'
import legal_notices from './legal_notices.json'

export default {
  common,
  startup,
  client,
  event,
  ecosystem,
  input,
  login,
  domain,
  dashboard,
  news,
  general_terms_of_use,
  users,
  legal_notices,
  archived,
}
