import { Skeleton } from 'antd'
import { TFunction } from 'i18next'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BadgeEventStatus from '../../components/Badge/BadgeEventStatus'
import Section from '../../components/Layout/Section'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import { EventDtoModel } from '../../domain/event/EventDtoModel'
import usePaginated from '../../services/usePaginated.hook'
import FormatHelper from '../../utils/FormatHelper'
import styles from './DemandesContactClient.module.scss'
import { EventStatusEnum } from '../../domain/event/EventStatus.enum'
import { ArchivedStartupRecord } from '../../domain/archived/ArchivedStartupRecord'

const DemandesContactClient: FC = () => {
  const { listClientLinkingStartup } = useContext(ApiContext)
  const { t: t_event } = useTranslation('event')
  const { t: t_common } = useTranslation('common')

  const { listClientArchivedStartup } = useContext(ApiContext)

  const {
    data: events,
    loading,
    loadMore,
  } = usePaginated(listClientLinkingStartup)

  const {
    data: archived,
    loading: archivedLoading,
    loadMore: loadMoreArchived,
  } = usePaginated(async (page, pageSize) => {
    const result = await listClientArchivedStartup(page, pageSize);
    return {
      ...result,
      data: result.data.map(item => ({
        ...item,
        id: item.id ?? item.archivedStartup.id, // Ensure a valid ID
        //status: DeletedStatusEnum.DELETED, // Add deleted status
      })),
    };
  });

  return (
    <Section>
      <h2>{t_event('connectionRequest')}</h2>
      {events.length === 0 && !loading ? (
        <p>{t_event('noConnectionRequest')}</p>
      ) : (
        <EventList events={events} t={t_event} />
      )}
      <Skeleton loading={loading} active={true} paragraph={{ rows: 1 }}>
        {loadMore ? (
          <MainButton className={styles.buttonLoad} onClick={loadMore}>
            {t_common('seeMore')}
          </MainButton>
        ) : null}
      </Skeleton>

      {/* Affichage des archives */}
      <ArchivedStartupList archived={archived} t={t_event} />
      <Skeleton loading={archivedLoading} active={true} paragraph={{ rows: 1 }}>
        {loadMoreArchived && (
          <MainButton className={styles.buttonLoad} onClick={loadMoreArchived}>
            {t_common('seeMore')}
          </MainButton>
        )}
      </Skeleton>
    </Section>
  )
}

// Liste des événements
const EventList = ({ events, t }: { events: EventDtoModel[], t: TFunction }) => (
  <ul className="ul">
    {events.map((event, index) => (
      <li key={index}>
        {FormatHelper.formatDate(event.date, 'fr')} : {t('emitFor')}&nbsp;
        <Link
          className={styles.startupLink}
          to={`/startups/${event.startup?.id}`}
        >
          {event.startup?.fullName}
        </Link>
        <BadgeEventStatus status={event.status} />
      </li>
    ))}
  </ul>
)

// Liste des archives
const ArchivedStartupList: FC<{ archived: ArchivedStartupRecord[], t: TFunction }> = ({ archived, t }) => (
  <ul className="ul">
    {archived.map((record) => (
      <li key={record.id}>
        {FormatHelper.formatDate(record.date, 'fr')} : {t('emitFor')}&nbsp;
        <strong>{record.archivedStartup.fullName}</strong> {/* Affichage du nom sans lien */}
        <BadgeEventStatus status={EventStatusEnum.DONE} /> {/* Utilisation du même statut pour les archives */}
      </li>
    ))}
  </ul>
)

export default DemandesContactClient
