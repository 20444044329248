import { TFunction } from 'react-i18next'

export enum EventStatusEnum {
  OPEN = 'OPEN',
  WIP = 'WIP',
  DONE = 'DONE'
}

const eventStatusColorMatcher = {
  [EventStatusEnum.OPEN]: '#953D51',
  [EventStatusEnum.WIP]: '#CFB07B',
  [EventStatusEnum.DONE]: '#6B8087',
}

export class EventStatusHelper {
  static format = (status: EventStatusEnum, t: TFunction): string =>
    t(`eventStatus.format.${status}`) || status

  static color = (status: EventStatusEnum): string =>
    eventStatusColorMatcher[status] || '#6B8087'

  static values = (): EventStatusEnum[] => Object.values(EventStatusEnum)
}
