import { Cascader, Col, Form, Row } from 'antd'
import { TFunction } from 'react-i18next'
import {
  FormInstance,
  RuleObject,
  RuleRender,
} from 'rc-field-form/lib/interface'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CascaderOption,
  CascaderOptionWithCountHelper,
} from '../../components/Form/CascaderOptionWithCount.model'
import CheckBoxGroup from '../../components/Form/InputCheckBox/CheckBoxGroup'
import RadioBoxGroup from '../../components/Form/InputCheckBox/RadioBoxGroup'
import InputPrice from '../../components/Form/InputPrice'
import InputSelectMultiple from '../../components/Form/InputSelect/InputSelectMultiple'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../components/Form/OptionWithCount.model'
import ApiContext from '../../context/ApiContext'
import ClientInvestmentTypeEnum, {
  ClientInvestmentTypeHelper,
} from '../../domain/client/client-investmentType.enum'
import ClientNeedsEnum, {
  ClientNeedHelper,
} from '../../domain/client/client-needs.enum'
import ClientSearchTypeEnum, {
  ClientSearchTypeHelper,
} from '../../domain/client/client-searchType.enum'
import CountryEnum, { CountryHelper } from '../../domain/country.enum'
import { EcosystemsModel } from '../../domain/ecosystems.model'
import RegionEnum, { RegionHelper } from '../../domain/region.enum'
import SectorEnum, { SectorHelper } from '../../domain/sector.enum'
import StartupRaiseStatusEnum, {
  StartupRaiseStatusHelper,
} from '../../domain/startup-raiseStatus.enum'
import styles from '../Startup/Init/RaiseStartupForm.module.scss'
import { SimpleBooleanEnumHelper } from '../../domain/simpleBoolean.enum'

const needsConfig = (t: TFunction): OptionWithCountModel<ClientNeedsEnum>[] =>
  OptionWithCountHelper.buildFrom(
    ClientNeedHelper.allNeeds(),
    t,
    ClientNeedHelper.format,
  )

const searchTypeConfig = (
  t: TFunction,
): OptionWithCountModel<ClientSearchTypeEnum>[] =>
  OptionWithCountHelper.buildFrom(
    ClientSearchTypeHelper.allSearchType(),
    t,
    ClientSearchTypeHelper.format,
  )

const raiseStatusConfig = (
  t: TFunction,
): OptionWithCountModel<StartupRaiseStatusEnum>[] =>
  OptionWithCountHelper.buildFrom(
    StartupRaiseStatusHelper.allRaiseStatus(),
    t,
    StartupRaiseStatusHelper.format,
  )

const isNotifiableConfig = (
  t: TFunction,
): OptionWithCountModel<SimpleBooleanEnumHelper>[] =>
  OptionWithCountHelper.buildFromMapper(
    SimpleBooleanEnumHelper.allKinds(),
    SimpleBooleanEnumHelper.mapper,
    t,
    SimpleBooleanEnumHelper.format,
  )

const investmentTypeConfig = (
  t: TFunction,
): OptionWithCountModel<ClientInvestmentTypeEnum>[] =>
  OptionWithCountHelper.buildFrom(
    ClientInvestmentTypeHelper.allInvestmentType(),
    t,
    ClientInvestmentTypeHelper.format,
  )

const sectorsConfig = (t: TFunction): OptionWithCountModel<SectorEnum>[] =>
  OptionWithCountHelper.buildFrom(
    SectorHelper.allSectors(),
    t,
    SectorHelper.format,
  )

const regionsConfig = (t: TFunction): OptionWithCountModel<RegionEnum>[] =>
  OptionWithCountHelper.buildFrom(RegionHelper.allRegions(), t)

const countryConfig = (t: TFunction): OptionWithCountModel<CountryEnum>[] =>
  OptionWithCountHelper.buildFrom(CountryHelper.allCountries(), t)

interface Props {
  initialNeeds?: ClientNeedsEnum[]
  seeRegions?: boolean
  isNotifiable?: boolean
  initialIsNotifiable?: boolean
}

const ClientForm: FC<Props> = ({
  initialNeeds,
  seeRegions,
  initialIsNotifiable = false,
}) => {
  const [displayRegions, setDisplayRegions] = useState(seeRegions || false)
  const [ecosystems, setEcosystems] = useState<EcosystemsModel[]>([])
  const [investment, setInvestment] = useState(() =>
    (initialNeeds || []).includes(ClientNeedsEnum.INVESTMENT),
  )
  const { getEcosystems } = useContext(ApiContext)

  useEffect((): void => {
    getEcosystems().then(setEcosystems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ecosystemsAndChilds: CascaderOption[] = ecosystems.flatMap((e) => ({
    label: e.name,
    value: e.name,
    children: e.subEcosystems.map((s) => ({ label: s.name, value: s.name })),
  }))

  const ecosystemsConfig =
    CascaderOptionWithCountHelper.buildFrom(ecosystemsAndChilds)

  const { t: t_common } = useTranslation('common')
  const { t: t_client } = useTranslation('client')
  const { t: t_domain } = useTranslation('domain')

  const isInvestment = (e: ClientNeedsEnum[]) => {
    setInvestment((e || []).includes(ClientNeedsEnum.INVESTMENT))
  }

  const higherThanMinInvest =
    (field: string, t: TFunction): RuleRender =>
    ({ getFieldValue }: FormInstance): RuleObject => ({
      validator(_rule, value) {
        if (!value) return Promise.resolve()
        if (value >= getFieldValue(field)) {
          return Promise.resolve()
        }
        return Promise.reject(t('amountMustBeSuperior'))
      },
    })

  const selectCountry = (selectedCountries: any) => {
    setDisplayRegions(selectedCountries.includes(CountryEnum.FRANCE))
  }

  return (
    <>
      <Form.Item
        label={
          <>
            {t_common('todayYouWant')}
            <small>{t_common('multipleChoiceAllow')}</small>
          </>
        }
        name={'need'}
        rules={[{ required: true, message: t_common('requiredField') }]}
      >
        <CheckBoxGroup
          options={needsConfig(t_domain)}
          onChange={isInvestment}
        />
      </Form.Item>
      {investment ? (
        <>
          <Row gutter={30} align="middle">
            <Col className={styles.minInvestItem}>
              <Form.Item
                label={<>{t_client('amountToInvest')}</>}
                name={'minInvest'}
                rules={[
                  {
                    type: 'number',
                    max: 10000000,
                    min: 0,
                  },
                ]}
              >
                <InputPrice max={10000000} />
              </Form.Item>
            </Col>
            <Col className={styles.maxInvestItem}>
              <Form.Item
                label={<></>}
                name={'maxInvest'}
                dependencies={['minInvest']}
                rules={[
                  {
                    type: 'number',
                    max: 10000000,
                    min: 0,
                  },
                  higherThanMinInvest('minInvest', t_client),
                ]}
              >
                <InputPrice max={10000000} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={
              <>
                {t_client('predilections.tower.label')}

                <small>
                  {t_common('multipleChoiceAllow')} ({t_common('optional')})
                </small>
              </>
            }
            name="raise"
          >
            <CheckBoxGroup options={raiseStatusConfig(t_domain)} />
          </Form.Item>
        </>
      ) : null}
      <Form.Item
        label={<>{t_client('predilections.sector.label')}</>}
        name={'sectors'}
      >
        <InputSelectMultiple
          options={sectorsConfig(t_domain)}
          format={SectorHelper.format}
          placeholder={t_client('predilections.sector.byDefault')}
        />
      </Form.Item>

      <Form.Item
        label={<>{t_client('predilections.ecosystem.label')}</>}
        name={'ecosystems'}
      >
        <Cascader
          multiple
          size="large"
          options={ecosystemsConfig}
          placeholder={t_client('predilections.ecosystem.byDefault')}
        />
      </Form.Item>

      <Form.Item
        label={<>{t_client('predilections.country.label')}</>}
        name={'countries'}
      >
        <InputSelectMultiple
          onChange={selectCountry}
          options={countryConfig(t_domain)}
          placeholder={t_client('predilections.country.byDefault')}
        />
      </Form.Item>

      {displayRegions ? (
        <>
          <Form.Item
            label={<>{t_client('predilections.region.label')}</>}
            name={'regions'}
          >
            <InputSelectMultiple
              options={regionsConfig(t_domain)}
              placeholder={t_client('predilections.region.byDefault')}
            />
          </Form.Item>
        </>
      ) : null}

      <div className={styles.infoContainer}>
        <strong>{t_client('additionalInformation')}</strong>
        <p>{t_client('ItWillAllowToSCBITo')}</p>
        <Form.Item
          label={
            <>
              {t_client('yourResearchIs')}
              <small>{t_client('onlyOneChoice')}</small>
            </>
          }
          name={'searchType'}
          rules={[{ required: true, message: t_common('requiredField') }]}
        >
          <RadioBoxGroup options={searchTypeConfig(t_domain)} />
        </Form.Item>
        {investment ? (
          <Form.Item
            label={
              <>
                {t_client('inCaseOfInvestiment')}
                <small>
                  {t_client('onlyOneChoice')} ({t_common('optional')})
                </small>
              </>
            }
            name="investmentType"
          >
            <RadioBoxGroup options={investmentTypeConfig(t_domain)} />
          </Form.Item>
        ) : null}

        <Form.Item
          rules={[{ required: true, message: t_common('requiredField') }]}
          label={
            <>
              {t_client('predilections.isNotifiable.label')}
              <small>{t_client('onlyOneChoice')}</small>
            </>
          }
          name="isNotifiable"
        >
          <RadioBoxGroup
            value={initialIsNotifiable}
            options={isNotifiableConfig(t_domain)}
          />
        </Form.Item>
      </div>
    </>
  )
}

export default ClientForm
