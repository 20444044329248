import { notification } from 'antd'
import { FixMeLater } from '../App'
import UserModel from '../domain/admin/user.model'
import ClientInvestmentTypeEnum from '../domain/client/client-investmentType.enum'
import ClientNeedsEnum from '../domain/client/client-needs.enum'
import ClientSearchTypeEnum from '../domain/client/client-searchType.enum'
import CountryEnum from '../domain/country.enum'
import { EcosystemsModel } from '../domain/ecosystems.model'
import { EventStatusEnum } from '../domain/event/EventStatus.enum'
import { EventTypeEnum } from '../domain/event/EventType.enum'
import MobilePhone from '../domain/mobilePhone'
import { PasswordToken } from '../domain/password-token.model'
import RegionEnum from '../domain/region.enum'
import SectorEnum from '../domain/sector.enum'
import StartupMarketsEnum from '../domain/startup-markets.enum'
import StartupMaturitiesEnum from '../domain/startup-maturities.enum'
import StartupRaiseStatusEnum from '../domain/startup-raiseStatus.enum'
import {
  Fondateur,
  Informations,
  KeyDate,
  Offer,
  Raise,
  StartupModel,
  Team,
} from '../domain/startup.model'
import { request } from '../utils/httpHelper'
import methods from '../utils/methods'
import { Roles } from '../utils/roles.enum'
import { ShortNewsModel } from '../domain/news/shortNews.model'
import { RssFeedModel } from '../domain/news/rssFeed.model'

const getVersion = () => request(methods.GET, `/version`)
const quickSearch = (searchText: string) =>
  request(methods.GET, `/search`, undefined, { searchText: searchText })

/** CLIENT **/
const listClients = () => request(methods.GET, '/client')
const getMyClientProfil = () => request(methods.GET, `/client/me`)
const getClient = (id: string) => request(methods.GET, `/client/${id}`)
const saveClient = (id: string, data: EditClientReq): Promise<void> =>
  request(methods.POST, `/client/${id}`, data)

const createClientLinkingStartup = (startupId: string) =>
  request(methods.POST, `/client/linking/${startupId}`)
const getClientLinkingStartup = (startupId: string) =>
  request(methods.GET, `/client/linking/${startupId}`)
const listClientLinkingStartup = (page?: number, pageSize?: number) =>
  request(methods.GET, `/client/linking`, undefined, { page, pageSize })
const listClientArchivedStartup = (page?: number, pageSize?: number) =>
  request(methods.GET, `/client/archived`, undefined, { page, pageSize })


/** EVENT **/
const listEvents = (
  types?: EventTypeEnum[],
  statuses?: EventStatusEnum[],
  page?: number,
  pageSize?: number,
) =>
  request(methods.GET, `/event`, undefined, { types, statuses, page, pageSize })
const saveEvent = (id: string, eventReq: UpdateEventReq) =>
  request(methods.PUT, `/event/${id}`, eventReq)
const createEvent = (eventReq: CreateEventReq) =>
  request(methods.POST, `/event`, eventReq)
const countEvents = () => request(methods.GET, `/event/summary`, undefined)

/** STARTUP **/
const getStartups = () => request(methods.GET, `/startup`)
const getMyStartup = () => request(methods.GET, `/startup/my`)
const getStartup = (idStartup: string) =>
  request(methods.GET, `/startup/${idStartup}`).then((s: StartupModel) => {
    s.pictureIds = s.pictureIds ?? []
    return s
  })
const getStartupVisits = (
  idStartup: string,
  startDate: string | null,
  endDate: string | null,
) =>
  request(methods.GET, `/startup/${idStartup}/visits`, null, {
    startDate: startDate,
    endDate: endDate,
  })
const getStartupVisitors = (
  idStartup: string,
  startDate: string | null,
  endDate: string | null,
) =>
  request(methods.GET, `/startup/${idStartup}/visitors`, null, {
    startDate: startDate,
    endDate: endDate,
  })
const validateStartup = (idStartup: string) =>
  request(methods.POST, `/startup/validate/${idStartup}`)
const disableStartup = (idStartup: string) =>
  request(methods.POST, `/startup/disable/${idStartup}`)
const deleteStartup = (idStartup: string) =>
  request(methods.DELETE, `/startup/${idStartup}`)
const saveStartup = (startup: FixMeLater): Promise<FixMeLater> =>
  request(methods.POST, `/startup`, startup)
const getLastVisitedStartupsByClient = (): Promise<StartupModel[]> =>
  request(methods.GET, `/startup/last-visited`)

const updateStartupInformations = (
  id: string,
  data: StartupInformationsReq,
  logo?: File,
): Promise<void> => {
  let formData = formDataWithFile('informations', data)
  return request(
    methods.POST,
    `/startup/${id}/informations`,
    formData,
    undefined,
  )
}

const updateStartupOffer = (id: string, data: StartupOfferReq): Promise<void> =>
  request(methods.POST, `startup/${id}/offer`, data, undefined)

const updateStartupRaise = (id: string, data: StartupRaiseReq): Promise<void> =>
  request(methods.POST, `startup/${id}/raise`, data, undefined)

const updateStartupTeam = (id: string, data: StartupTeamReq): Promise<void> =>
  request(methods.POST, `startup/${id}/team`, data, undefined)

const uploadStartupPicture = (
  id: string,
  picture: UploadFileReq,
): Promise<void> => {
  let data = new FormData()
  data.append('id', picture.id)
  data.append('file', picture.file)
  return request(methods.POST, `startup/${id}/picture`, data, undefined)
}

const uploadStartupLogo = (id: string, logo: UploadFileReq): Promise<void> => {
  let data = new FormData()
  data.append('id', logo.id)
  data.append('file', logo.file)
  return request(methods.POST, `startup/${id}/logo`, data, undefined)
}

const deleteStartupPicture = (id: string, assetFileId: string): Promise<void> =>
  request(methods.DELETE, `startup/${id}/picture/${assetFileId}`, undefined)

const deleteStartupLogo = (id: string, assetFileId: string): Promise<void> =>
  request(methods.DELETE, `startup/${id}/logo/${assetFileId}`, undefined)

const updateStartup = (id: string, data: StartupReq): Promise<void> => {
  let formData = formDataWithFile('startup', data)
  return request(methods.POST, `/startup/${id}`, formData, undefined)
}

/** ADMIN **/
const inviteUser = (inviteReq: InviteReq): Promise<PasswordToken> =>
  request(methods.POST, `/auth/invite-token`, inviteReq)
const resetUserPassword = (email: string): Promise<PasswordToken> =>
  request(methods.POST, `/auth/reset-token`, { email })
const deleteUser = (email: string): Promise<void> =>
  request(methods.DELETE, `/admin/user/${email}`)
const listUsers = (): Promise<UserModel[]> =>
  request(methods.GET, `/admin/users`)

/** USER **/
const isConsented = () => request(methods.GET, `/user/isConsented`)
const consent = () => request(methods.POST, `/user/consent`)

const updateAccount = (id: string, req: UpdateAccountReq): Promise<void> => {
  return request(methods.PUT, `/user/${id}`, req, undefined)
}
const getUser = (id: string): Promise<UserModel> => {
  return request(methods.GET, `/user/${id}`)
}

/** ECOSYSTEMS **/
const getEcosystems = () => request(methods.GET, `/ecosystem`)
const createEcosystem = (ecosystemReq: EcosystemReq) =>
  request(methods.POST, `/ecosystem`, ecosystemReq)
const saveEcosystem = (id: string, ecosystemReq: EcosystemReq) =>
  request(methods.PUT, `/ecosystem/${id}`, ecosystemReq)
const deleteEcosystem = (id: string) =>
  request(methods.DELETE, `/ecosystem/${id}`)

/** ADMIN DASHBOARD **/
const getDashboardStatistics = () => request(methods.GET, 'admin/dashboard')

/** SHORT NEWS **/
const getShortNews = () => request(methods.GET, 'short-news')
const postShortNews = (data: ShortNewsModel) =>
  request(methods.POST, 'short-news', data)

/** STARTUPS NEWS **/
const getStartupNews = () => request(methods.GET, 'startup-news')
const getRssFeeds = () => request(methods.GET, 'startup-news/rss-feeds')
const postRssFeeds = (data: RssFeedModel[]) =>
  request(methods.POST, 'startup-news/rss-feeds', data)

function errorFormBehaviour(error: any) {
  let details = {
    message: 'Erreur de formulaire',
    description: '',
  }
  if (error.response != null) {
    switch (error.response.status) {
      case 400:
        details.description =
          typeof error.response.data === 'object'
            ? error.response.data.message
            : error.response.data
        break
      case 404:
        details.description = 'Startup inconnue'
        break
      default:
        details.description =
          'Erreur inconnue : ' +
          (typeof error.response.data === 'object'
            ? error.response.data.message
            : '')
    }
  } else {
    details.description = 'Erreur inconnue'
  }
  notification['error'](details)
}

function successFormBehaviour(title: string) {
  notification['success']({
    message: 'Succès',
    description: title,
  })
}

const formDataWithFile = (dataField: string, data: any): FormData => {
  const d = new FormData()
  data !== null &&
    d.append(
      dataField,
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      }),
    )
  return d
}

export interface StartupReq {
  informations: Informations
  offer: Offer
  raise: Raise
  team: Team
}

export interface StartupInformationsReq {
  name: string
  sectors: SectorEnum[]
  country: CountryEnum
  region: RegionEnum
  village: string
  ecosystemId: string
  subEcosystem?: string
  website: string
  linkedin: string
  video: string
  roadMap: KeyDate[]
  hasLettreEmetteur?: boolean
  hasFicheOFAC?: boolean
  businessWoman?: boolean
  environmentalImpact?: boolean
}

export interface StartupOfferReq {
  shortDescriptionFr: string
  shortDescriptionEn: string
  marketOpportunityFr: string
  marketOpportunityEn: string
  innovativeSolutionFr: string
  innovativeSolutionEn: string
  economicModelFr: string
  economicModelEn: string
  market: StartupMarketsEnum[]
  maturity: StartupMaturitiesEnum
}

export interface StartupRaiseReq {
  targetMoney: number
  minInvest: number
  need: string
  status: string
  dateRaise: string
  contactName: string
  email: string
  mobilePhone: MobilePhone
}

export interface StartupTeamReq {
  collaborateurs: number
  fondateurs: Fondateur[]
}

export interface EditClientReq {
  need: ClientNeedsEnum[]
  minInvest?: number
  maxInvest?: number
  raise?: StartupRaiseStatusEnum[]
  sectors: string[]
  ecosystems: string[][]
  countries: CountryEnum[]
  regions: RegionEnum[]
  searchType: ClientSearchTypeEnum
  investmentType?: ClientInvestmentTypeEnum
  banquier?: string
  entity?: string
}

export interface UpdateAccountReq {
  firstName: string
  lastName: string
  email: string
  mobilePhone?: MobilePhone
}

export interface InviteReq {
  email: string
  role: Roles
}

export interface UpdateEventReq {
  status: EventStatusEnum
  comment?: string
}

export interface CreateEventReq {
  clientId: string
  startupId: string
  date: number
  type: EventTypeEnum
  status: EventStatusEnum
  comment?: string
}

export interface EcosystemReq extends Omit<EcosystemsModel, 'id'> {}

export interface UploadFileReq {
  file: File
  id: string
}

export default {
  getVersion,
  getStartups,
  getStartup,
  getStartupVisits,
  getStartupVisitors,
  getMyStartup,
  listClients,
  updateStartupInformations,
  updateStartupOffer,
  updateStartupRaise,
  updateStartupTeam,
  uploadStartupLogo,
  uploadStartupPicture,
  deleteStartupPicture,
  deleteStartupLogo,
  updateStartup,
  saveStartup,
  errorFormBehaviour,
  successFormBehaviour,
  isConsented,
  consent,
  validateStartup,
  disableStartup,
  deleteStartup,
  getLastVisitedStartupsByClient,
  quickSearch,
  inviteUser,
  resetUserPassword,
  listUsers,
  saveClient,
  getMyClientProfil,
  getClient,
  updateAccount,
  getUser,
  createClientLinkingStartup,
  getClientLinkingStartup,
  listClientLinkingStartup,
  listClientArchivedStartup,
  listEvents,
  countEvents,
  saveEvent,
  createEvent,
  getEcosystems,
  createEcosystem,
  saveEcosystem,
  deleteEcosystem,
  deleteUser,
  getDashboardStatistics,
  getShortNews,
  postShortNews,
  getStartupNews,
  getRssFeeds,
  postRssFeeds,
}
